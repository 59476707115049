import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CustomersObject {
  id: string;
  gardians: any;
  contacts: any;
  nameEn: string;
  nameAr: string;
  genderId: string;
  nationalityId: string;
  statusId: string;
  typeId: string;
  customerAgeGroupId: string;
  documenttypeId: string;
  email: string;
  residencyNumber: string;
  name: string;
  civilId: string;
  passportNumber: number;
  address: string;
  dateOfBirth: Date;
  preferredDays: any;
}

export interface CustomersListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  customers: Array<CustomersObject>;
  errors: unknown;
}

export interface CustomersFilterInfo {
  name: string;
}

@Module
export default class CustomersModule
  extends VuexModule
  implements CustomersListInfo
{
  customers = [] as Array<CustomersObject>;
  allCustomers = [] as Array<CustomersObject>;
  customerFilter = {} as CustomersFilterInfo;
  attachments = [];
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get customers list
   * @returns Array<CustomersObject>
   */
  get customersList(): Array<CustomersObject> {
    if (this.customers.length > 0) this.customers[0]['page'] = this.page;
    return this.customers;
  }

  get allCustomersList(): Array<CustomersObject> {
    return this.allCustomers;
  }

  /**
   * Get total items
   * @returns number
   */
  get customersListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](data) {
    this.customers = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CUSTOMER_ATTACHMENTS](data) {
    this.attachments = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_CUSTOMERS](data) {
    this.allCustomers = data.data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_CUSTOMERS_LIST]() {
    this.customers = [] as Array<CustomersObject>;
    this.customerFilter = {} as CustomersFilterInfo;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CUSTOMERS_FILTER](obj) {
    this.customerFilter = obj;
  }

  @Action
  [Actions.RESET_CUSTOMERS_STORE]() {
    this.context.commit(Mutations.RESET_CUSTOMERS_LIST);
  }

  @Action
  [Actions.UPDATE_CUSTOMERS_FILTER](val) {
    this.context.commit(Mutations.SET_CUSTOMERS_FILTER, val);
  }

  @Action
  [Actions.CREATE_CUSTOMER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/customer', data)
      .then((data) => {
        this.context.commit(Mutations.RESET_CUSTOMERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_CUSTOMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/customer', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CUSTOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CUSTOMER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/customer', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CUSTOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CUSTOMER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CUSTOMER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CUSTOMER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/customer', id);
  }

  @Action
  [Actions.GET_CUSTOMERS]() {
    const params = {
      page: this.page,
      ...this.customerFilter,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/customer', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_CUSTOMER_HISTORY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/customer/history', id);
  }

  @Action
  [Actions.GET_ALL_CUSTOMERS](data) {
    ApiService.setAuthorizationHeader();
    const params = {
      ...data,
    };
    // eslint-disable-next-line no-prototype-builtins
    if (!params.hasOwnProperty('active')) {
      params.active = true;
    }

    return ApiService.query(`/customer/all`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CUSTOMERS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.CREATE_CUSTOMER_ATTACHMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/customer/attachments/${id}`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_ATTACHMENTS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.DELETE_CUSTOMER_ATTACHMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/customer/attachments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CUSTOMERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
}
